
import {Component, Ref} from 'vue-property-decorator';
import SigInputTexte from '@/components/user/SigInputTexte.vue';
import {ISigInputTextDto} from '@/components/user/ISigInputTextDto';
import PwdDef from '@/components/personnal-info/PwdDef.vue';
import UserServicesUtils from '@/services/UserServicesUtils';
import {IUserResponse} from '@/services/dto/IUserResponse';
import {services, store} from '@/main';
import {IUserService} from '@/services/dto/IUserService';
import {IBalladeServiceRequest} from '@/services/dto/user-service/IBalladeServiceRequest';
import EmailsSelection from '@/components/EmailsSelection.vue';
import AbstractComponentVue from '@/services/AbstractComponentVue';
import UserCard from '@/components/UserCard.vue';
import ModalDownload from '@/components/user-services/ModalDownload.vue';
import LoginUtils from '@/utils/LoginUtils';
import AlertService from '@/utils/AlertService';
import PatternUtils from '@/utils/PatternUtils';

const userUtils = new UserServicesUtils();

@Component({
  components: {ModalDownload, UserCard, EmailsSelection, PwdDef, SigInputTexte } ,
})
export default class BalladeActivation extends AbstractComponentVue {
  private baseUrl = services.config.config.$api_url;
  private patternUtils = PatternUtils;
  // Identifiant de l'utilisateur connecter
  private accountConnectedId: number = store.getters.fullUser.accountId;
  // Identifiant de compte en cours de modification
  private accountId: number = +this.$route.params.id;
  // Si l'utilisateur connecté est l'utilisateur qui active son compte
  private isCurrentUser: boolean = this.accountId === this.accountConnectedId;
  // Information utilisateur
  private userResponse!: IUserResponse;
  @Ref('passwordGen') private readonly passwordGen?: PwdDef;
  @Ref('refLoginBallade') private refLoginBallade !: SigInputTexte;
  @Ref('refEmailVisioSelection') private emailsSelection?: EmailsSelection;

  // Liste des adresses mail disponible pour l'utilisateur chargé par userResponse
  private usermails: string[] = [];

  // *** Variable utilisé dans le formulaire
  // affichage modal de téléchagement du logcielBallade (uniquement is l'utilisateur connecté active sont compte)
  private showDownloadPage: boolean = false;
  private downloadExeUrl: string = store.getters.balladeInstallerLink;

  private downloadSoftwareOrdered: string = store.getters.fullUser.balladeArtTypeLabel === 'BALLADEWAN' ?
  store.getters.vpnAdnovSoftwareLink : store.getters.balladeInstallerLink;

  private contractName: string = 'MER_CPA_BALLADE';
  // Identifiant ballade souhaitéeé (générer)
  private loginBallade!: string;
  // Adresse mail de confirmation
  private currentMailValue: string = '';
  private contractAccepted: boolean = false;
  private passwordValidity: boolean = false;

  private created() {
    this.loadUserInfoFromWebService();
  }

  private watchLoginBallade(val: ISigInputTextDto) {
    this.loginBallade = val.value;
  }

  private watchEmail(email: string) {
    this.currentMailValue = email;
  }

  private watchPasswordValid(valid: boolean) {
    this.passwordValidity = valid;
  }

  /**
   * WebService : Interrogation de la disponibilité du login ballade dans le SI
   * -- 1er ACTION du Bouton valider
   */
  private checkLoginAndActiveBallade() {
    this.showModal('modal-traitement-cours');
    userUtils.availabilityLoginBallade(this.loginBallade).then((response: any) => {
      if (response !== undefined && response.status === 200) {
        this.activeBallade();
      } else {
        this.setErrorOnBalladeLogin();
      }
    }).catch(((res: any) => {
      this.setErrorOnBalladeLogin();
    }));
  }

  /**
   * Une erreur s'est produite lors de la validation du login Ballade.
   */
  private setErrorOnBalladeLogin() {
    this.hideModal('modal-traitement-cours');
    if (this.refLoginBallade !== undefined) {
      this.refLoginBallade.error('L\'identifiant que vous avez choisi existe déjà. Veuillez en choisir un autre.');
    } else {
      console.error('L\'identifiant que vous avez choisi existe déjà. Veuillez en choisir un autre.');
    }
  }

  /**
   * WebService de creation du service Ballade.
   * --2er ACTION du Bouton valider
   */
  private activeBallade() {
    const data: IBalladeServiceRequest = {
      orderGenId: undefined,
      accountId: this.accountId,
      contractAccepted: this.contractAccepted,
      cpaFileName: this.contractName,
      password: this.isCurrentUser ? this.passwordGen?.newPassword : undefined,
      login: this.loginBallade,
      selectedMail: this.isCurrentUser ? undefined : this.currentMailValue,
    };
    userUtils.activeBalladeAccount(data).then((response: any) => {
      if (response !== undefined) {
        this.callbackBalladeCreatedSucces();
        services.loginService.ifCurrentUserReloadStore(this.accountId,
            () => this.hideModal('modal-traitement-cours'));
      } else {
        this.callbackBalladeCreatedError();
        this.hideModal('modal-traitement-cours');
      }
    }).catch(((res: any) => {
      this.callbackBalladeCreatedError();
      this.hideModal('modal-traitement-cours');
    }));
  }

  private callbackBalladeCreatedSucces() {
    if (this.isCurrentUser) {
      this.showDownloadPage = true;
    } else {
      this.callBackWithSucces();
    }
  }

  private callbackBalladeCreatedError() {
    AlertService.customAlert(
        'Service Ballade non-activé',
        '<b>Le service Ballade n\'a pas pu être activé, contactez votre support</b>',
        'error',
        'Terminer',
        false,
        this.cancelButton,
        true,
    );
  }


  private cancelButton() {
    services.routageService.goTo('/account/' + this.accountId + '/services');
  }

  private callBackWithSucces() {
    this.showDownloadPage = false;
    services.routageService.goTo('/account/' + this.accountId + '/services');
    AlertService.success('Votre souscription au service Ballade a bien été effectuée');
  }

  /**
   * La validation du formulaire, afin de permettre l'accès au bouton de création ballade.
   * @return true si invalid
   */
  private disabledCreationButton(): boolean {
    const hasloginBallade: boolean = this.refLoginBallade !== undefined && this.refLoginBallade.val.hasError;
    const passwordForCurrentUser = this.isCurrentUser && !this.passwordValidity;
    const hasMailError = !this.isCurrentUser && this.currentMailValue.length === 0;
    return hasloginBallade || passwordForCurrentUser || hasMailError || !this.contractAccepted;
  }

  /**
   * Appele du webservice permettant l'affichage des informations de l'utilisateur.
   */
  private loadUserInfoFromWebService() {
    userUtils.getAccount(this.accountId).then((response: any) => {
      if (response !== undefined && response.status === 200) {
        this.loadPageWithInfo(response.data);
        this.loadBalladeSoftwareLink ();
      } else {
        console.error(response);
      }
    }).catch(((res: any) => {
      console.error(res);
    }));
  }

  /**
   * Chargement du formulaire
   * @param data
   * @private
   */
  private loadPageWithInfo(data: IUserResponse) {
    // génération login ballade
    this.userResponse = data;
    this.loginBallade = LoginUtils.getDefaultNamingConventionFromObject(this.userResponse);
    const loginBalladeComp: any = this.$refs.refLoginBallade;
    if (loginBalladeComp !== undefined) {
      loginBalladeComp.currentInput.value = this.loginBallade;
    }
    if (!this.isCurrentUser) {
      // récupere le mail par défaut
      const servicemail: IUserService[] = this.userResponse.userServices
          .filter((us) => us.serviceType === 'MAIL' && us.state === 'ACTIVE'
              && us.professionalEmail !== undefined);
      if (servicemail.length > 0) {
        const professionalEmail = servicemail[0].professionalEmail;
        if (professionalEmail !== undefined) {
          this.usermails.push(professionalEmail);
          this.emailsSelection?.updateSelectedEmail(professionalEmail);
        }
      }
    }
  }

  private loadBalladeSoftwareLink() {
    this.downloadSoftwareOrdered = store.getters.fullUser.balladeArtTypeLabel === 'BALLADEWAN' ?
    store.getters.vpnAdnovSoftwareLink : store.getters.balladeInstallerLink;
  }
}
